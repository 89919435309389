@import "general.css";

.break {
    overflow: hidden;
    margin-top: 30px;
    margin-bottom: 30px;
}

.break-text {
    opacity: 0.25;
    color: var(--color-text);
}