@import "general.css";

.nav {
    min-width: 70%;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.nav-item {
    max-height: 50%;
    cursor: pointer;
}

.home-btn-container {
    position: sticky;
    top: 2rem;
    margin: auto;
    width: 5%;
    opacity: 0;
    z-index: 9998;
    transition: opacity 0.5s ease-in-out;
}

.home-button-container-show {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}