@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');

:root {
    --color-background: rgb(6, 5, 32);
    --color-background-light: rgb(9, 19, 49);
    --color-background-light-v2: rgb(11, 46, 85);
    --color-animation-text: rgb(60, 108, 196);
    --color-animation-text-light: rgb(26, 140, 239);
    --color-heading: rgb(255, 255, 255);
    --color-text: rgb(170, 170, 180);
    --about-icon-color: rgb(57, 183, 164);
    --about-icon-color-darker: rgb(0, 128, 128);
    --projects-icon-color: rgb(225, 60, 82);
    --projects-icon-color-darker: rgb(170, 0, 0);
    --skills-icon-color: rgb(70, 60, 220);
    --skills-icon-color-darker: rgb(0, 0, 128);
    --contact-icon-color: rgb(218, 127, 35);
    --contact-icon-color-darker: rgb(160, 80, 0);
}

* {
    scroll-margin-top: 80px;
    font: 'Space Mono', monospace;
    font-family: 'Space Mono', monospace;
}

body {
    background-color: var(--color-background);
    color: var(--color-text);
    margin: 0;
    padding: 0;
}