@import "general.css";

.App {
  text-align: center;
}

.App-header {
  position: absolute;
  min-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-actions {
  z-index: 9999;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
 
  padding-left: 12.5%;
  padding-top: 5.5rem;

  width: 65%;
  height: 70%;
}

.hero-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom, var(--color-background-light), var(--color-background));
}

/* OLD VERSION: Used for h1 tags or other text tags */
.header-name {
  font: 'Space Mono', monospace;
  display: inline;
  font-size: 3rem;
  font-weight: bold;
  z-index: 10;
  color: var(--color-heading);
  background-color: var(--color-background-light);
}

.pre-header-name {
  font: 'Space Mono', monospace;
  display: inline;
  font-size: 1vmax;
  font-weight: bold;
  color: var(--color-heading);;
}

.ascii-animation {
  background-color: var(--color-background-light);
  border-color: azure;

  align-self: center;
  border-radius: 20px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

  height: 100%;
  width: 95%;
  margin-top: 2.5%;

  overflow-y: hidden;
}

/* OLD VERSION: Used when the ascii animation used a pre tag */
.ascii-text {
  font-size: 1vmax;
  color: var(--color-animation-text);

  max-width: fit-content;
  overflow: hidden;
  word-break: break-all;

  position: relative;
  top: -50px;
}

.landing-view {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: var(--color-background);
  color: var(--color-text);
  height: 100vh;
  width: 100vw;
}

.sections-container {
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 10%;
  padding-right: 10%;
  background-color: var(--color-background);
  color: var(--color-text);
}

.nav-bar {
  min-height: 10%;
  display: flex;
  align-content: center;
  justify-content: center;
  z-index: 9999;
  opacity: 0.9;
}

.navbar {
  position: relative;
  /* default colors + transition */
  background-color: var(--color-background);
  color: var(--color-text);
  transition: all 0.3s ease-out;
}
/* scrolling state */
.fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  /* scrolling colors */
  background-color: var(--color-background);
  color: var(--color-text);
}

.text-heading {
  font: 'Space Mono', monospace;
  font-weight: bold;
  color: var(--color-heading);
}

.about-icon {
  color: var(--about-icon-color);
}

.projects-icon {
  color: var(--projects-icon-color);
}

.skills-icon {
  color: var(--skills-icon-color);
}

.contact-icon {
  color: var(--contact-icon-color);
}
