@import "general.css";

.section {
    margin: 0 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;
    background-color: var(--color-background);
    color: var(--color-text);
}

.section-header-icon {
    transform: scale(1.5);
    margin-top: 10px;
    margin-right: 15px;
}

.section-content {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    text-align: left;
    overflow: hidden;
}

.ul_first {
    list-style-type: '> ';
    margin: 0;
    padding-left: 25px;
}

.ul_second {
    list-style-type: '>> ';
    padding-top: 5px;
    padding-left: 40px;
    padding-bottom: 5px;
}
